/**
 * 会社マスタ
 */
import { isBlank } from '@/utilities/typing'
import PaymentStatus from '@/models/enums/payment-status';

class Company {
    company_id;
    company_code;
    company_name;
    payment_status;
    expired;
    official_name;
    address_1;
    address_2;
    phone;
    profit_rate_safe;
    profit_rate_danger;
    settlement_month;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.company_id = properties.company_id;
        this.company_code = properties.company_code;
        this.company_name = properties.company_name;
        this.payment_status = properties.payment_status;
        this.expired = properties.expired;
        this.official_name = properties.official_name;
        this.address_1 = properties.address_1;
        this.address_2 = properties.address_2;
        this.phone = properties.phone;
        this.profit_rate_safe = properties.profit_rate_safe;
        this.profit_rate_danger = properties.profit_rate_danger;
        this.settlement_month = properties.settlement_month;
    }

    get payment_status_display() {
        return PaymentStatus.get(this.payment_status);
    }
}

export default Company;
