<template>
    <header class="page-title">
        <h1><i class="bi bi-cc"></i> お支払い</h1>
    </header>

    <section class="section">
        <h2 class="section-title">お支払状況</h2>
        <div class="row mb-3 align-items-end">
            <div class="col-6">
                <label class="form-label">お支払設定</label>
                <div class="form-readonly">{{ company.payment_status_display }}</div>
            </div>
            <div class="col-6">
                <label class="form-label">利用期限</label>
                <div class="form-readonly">{{ company.expired ?? 'なし' }}</div>
            </div>
        </div>
    </section>
</template>

<script>
import Company from "@/models/entities/company";

export default {
    name: 'PageSystemPayment',
    components: {
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            company: new Company()
        }
    },
    mounted() {
        this.$http.get('/me')
        .then((response) => {
            this.company = new Company(response.data.company);
            this.loading = false;
        })
    },
    methods: {

    },
}
</script>

<style scoped>
</style>
