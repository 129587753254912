import Enum from './enum'

/**
 * 支払ステータス
 */
class PaymentStatus extends Enum {
    static TRIAL = 1;
    static CONTINUOUS = 2;
    static MANUAL = 3;
    static FREE = 4;

    static values() {
        return {
            [this.TRIAL]: '無料トライアル',
            [this.CONTINUOUS]: '自動更新中',
            [this.MANUAL]: '自動更新停止中',
            [this.FREE]: '永久無料',
        }
    }
}

export default PaymentStatus;
